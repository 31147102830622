
.goods-info-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 20px;
    .link-box {
        .view-detail {
            ::v-deep .el-link--inner {
                color: #1AB163;
            }
        }
    }
    .goods-img-dialog {
        .upload-file-content {
            display: flex;
            justify-content: space-between;
            width: 100%;
            height: 445px;
            .big-img {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 444px;
                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }
    }
}
.theory-list-header{
    display: flex;
    align-items: center;
    span{
        line-height: 1;
    }
}
.submit-score {
    margin-left: 10px;
    color: #FF9450;
    font-size: 16px;
    cursor: pointer;
}
